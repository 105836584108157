<script setup>
    // import OModal                   from "o365.vue.components.Modal.vue";
    // import OOrgunitsLookup          from "o365.vue.components.OrgUnitsLookup.vue";
    // import alert                    from "o365.controls.alert.ts";
    // import { getOrCreateProcedure } from "o365.vue.ts";
    // import { ref                  } from "vue";
    import { ref } from 'vue';
    import { getOrCreateProcedure } from 'o365-modules';
    import { OOrgUnitsLookup } from "o365-system-lookups";
    import { alert } from 'o365-vue-services';
 
    const props = defineProps({
        ds: Object,
        lesseeOrgUnit_ID: Number,
        lesseeOrgUnit: String,
        lesseeOrgUnitName: String,
        lesseeOrgUnitTitle: String,
        scopeItem_ID: Number
    }); 

    const procAddLease = getOrCreateProcedure({
        id            : "procAddLease",
        procedureName : "astp_Lease_AddLease"
    });
    const emit = defineEmits(['leaseAdded']);
    const vIsExternal = ref(false);
    window["vIsExternal"] = vIsExternal;

    const modalCreateLease = ref({hide: () => null });
    const newLease   = ref({
        Name : null,
        Title : null,
        Parent_ID : null,
        Parent: null,
        StartDate: new Date(),
        EndDate: new Date(),
        LesseeOrgUnit_ID: null,
        Lessee: null,
        Status_ID: null,
        Status: null,
        ScopeItem_ID: props.scopeItem_ID
    });

    const createNewLease = async () => {
        try {
            await procAddLease.execute( {Name: newLease.value.Name, Title: newLease.value.Title, Parent_ID: newLease.value.Parent_ID, StartDate: newLease.value.StartDate, EndDate: newLease.value.EndDate, LesseeOrgUnit_ID: newLease.value.LesseeOrgUnit_ID, Status_ID: newLease.value.Status_ID, ScopeItem_ID: newLease.value.ScopeItem_ID} );
            emit('leaseAdded'); 
            props.ds.load();
            modalCreateLease.value.hide();
            alert( $t('Lease added'), "success", { autohide : true } );
        }
        catch (_) {}
    }

    const handleSubmit = () => {
        modalCreateLease.value.hide();
        createNewLease();
    };

    const handleShown = () => {
        newLease.value.LesseeOrgUnit_ID = props.lesseeOrgUnit_ID ? props.lesseeOrgUnit_ID : null;
        newLease.value.Lessee = props.lesseeOrgUnit ? props.lesseeOrgUnit : null;
        newLease.value.Name = props.lesseeOrgUnitName ? $t("LEA") + '-' + props.lesseeOrgUnitName : '';
        newLease.value.Title = (props.lesseeOrgUnitName || props.lesseeOrgUnitTitle) ? $t("Lease") + ' - ' + (props.lesseeOrgUnitTitle ? props.lesseeOrgUnitTitle : props.lesseeOrgUnitName) : '';
    }

    const selectLessee = (pSel) => {
        newLease.value.LesseeOrgUnit_ID = pSel.ID;
        newLease.value.Lessee = pSel.OrgUnit;
        newLease.value.Name = $t("LEA") + '-' + pSel.Name;
        newLease.value.Title = $t("Lease") + ' - ' + (pSel.Title ? pSel.Title : pSel.Name);
    }

    const show = () => {
        modalCreateLease.value.show();
    }

    defineExpose({show});
</script>

<template>
    <OModal ref="modalCreateLease" @shown="handleShown">
        <div class="modal-dialog modal-lg">
            <form class="modal-content" @submit.prevent="handleSubmit">
                <header class="modal-header">
                    <h5 class="modal-title">
                        {{ $t("New lease") }}
                    </h5>
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </header>

                <main class="modal-body row-container gap-2">
                    <div class="row">
                        <div class="col-12">
                            <input class="form-check-input" type="radio" id="isInternal" :value="false" v-model="vIsExternal">
                            <label class="form-check-label ms-1" for="isInternal">{{ $t("Internal") }}</label>

                            <input class="form-check-input ms-4" type="radio" id="isExternal" :value="true" v-model="vIsExternal">
                            <label class="form-check-label ms-1" for="isExternal">{{ $t("External") }}</label>
                        </div>

                        <div class="col-12" :title="$t('Lessor')">
                            <label for="lessor-orgunit" class="form-label">
                                {{ $t("Lessor") }} *
                            </label>

                            <OOrgUnitsLookup id="lessor-orgunit" class="form-control form-control-sm" readonly text-input required
                                :bind="sel => { newLease.Parent_ID = sel.ID, newLease.Parent = sel.OrgUnit }" v-model="newLease.Parent" :restrictToContext="false"/>
                        </div>
                        <div class="col-12" :title="$t('Lessee')" v-if="!vIsExternal">
                            <label for="lessee-Lessee" class="form-label">
                                {{ $t("Lessee") }}
                            </label>

                            <OOrgUnitsLookup id="lessee-Lessee" class="form-control form-control-sm" readonly text-input required :bind="sel => selectLessee()" v-model="newLease.Lessee" :restrictToContext="false"/>
                        </div>
                        <div class="col-12" :title="$t('Lessee')" v-if="vIsExternal">
                            <label for="lessee-Company" class="form-label">
                                {{ $t("Lessee") }}
                            </label>

                            <OOrgUnitsLookup id="lessee-Company" class="form-control form-control-sm" readonly text-input required :bind="sel => selectLessee()" v-model="newLease.Lessee" :restrictToContext="false"/>
                        </div>
                        <!-- <div class="col-12"  :title="$t('Lessee is not an existing org unit')"> -->
                            <!-- <div class="form-check"> -->
                                <!-- <label for="lease-LesseeNotAnExistingOrgUnit" class="form-check-label">{{ $t("Lessee is not an existing org unit") }}</label> -->
                                <!-- <input id="lease-LesseeNotAnExistingOrgUnit" class="form-check-input" type="checkbox" v-model="isExternal"> -->
                            <!-- </div> -->
                        <!-- </div> -->
                        <div class="col-4">
                            <label for="lease-name" class="form-label">
                                {{ $t("Name") }} *
                            </label>
                            <input id="lease-name" class="form-control form-control-sm" type="text" required v-model="newLease.Name">
                        </div>
                        <div class="col-8">
                            <label for="lease-title" class="form-label">
                                {{ $t("Title") }}
                            </label>
                            <input id="lease-title" class="form-control form-control-sm" type="text" v-model="newLease.Title">
                        </div>
                        <div class="col-4">
                            <label for="lease-startDate" class="form-label">
                                {{ $t("Start Date") }}
                            </label>
                            <ODatePicker id="lease-startDate" class="form-control form-control-sm" format="Short Date" v-model="newLease.StartDate" :showInputIcon="true"/>
                        </div>
                        <div class="col-4">
                            <label for="lease-endDate" class="form-label">
                                {{ $t("End Date") }}
                            </label>
                            <ODatePicker id="lease-endDate" class="form-control form-control-sm" format="Short Date" v-model="newLease.EndDate" :showInputIcon="true"/>
                        </div>
                    </div>
                </main>

                <footer class="modal-footer">
                    <button class="btn btn-sm btn-primary" type="submit">
                        {{ $t("Create lease") }}
                    </button>

                    <button class="btn btn-sm btn-outline-primary" type="button" data-bs-dismiss="modal">
                        {{ $t("Cancel") }}
                    </button>
                </footer>
            </form>
        </div>
    </OModal>
</template>